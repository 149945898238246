import 'teaful-devtools'
import React, { lazy, Suspense } from 'react'
import ReactDOM from 'react-dom'
import store from './redux/store'
import reportWebVitals from './reportWebVitals'
import * as serviceWorkerRegistration from './serviceWorkerRegistration'
import { BrowserRouter } from 'react-router-dom'
import { Provider } from 'react-redux'
import { is_mobile } from 'config/is-mobile'
// import './i18n'

const LazyApp = lazy(() => import('./App'))
const LazyDApp = lazy(() => import('./DApp'))

ReactDOM.render(
	<React.StrictMode>
		<Provider store={store}>
			<BrowserRouter>
				{is_mobile ? (
					<Suspense fallback={<></>}>
						<LazyApp />
					</Suspense>
				) : (
					<Suspense fallback={<></>}>
						<LazyDApp />
					</Suspense>
				)}
			</BrowserRouter>
		</Provider>
	</React.StrictMode>,
	document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register()
