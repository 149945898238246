const initState = {
	show: false,
	other: '',
	horse: '',
	type: '',
	cup: '',
}

export const filterBoardReducer = (state = initState, action) => {
	switch (action.type) {
		case 'Set_Filter_Keys': {
			return {
				...state,
				...action.payload,
			}
		}

		case 'Toggle_Filter_Board_Content': {
			return {
				...state,
				...action.payload,
			}
		}

		default:
			return state
	}
}
