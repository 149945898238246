const initState = {
	fetched: false,
	fetched_time: 0,
	data: {},
}

export const initialReducer = (state = initState, action) => {
	switch (action.type) {
		case 'Set_Initial_Data': {
			return {
				...state,
				...action.payload,
			}
		}

		default:
			return state
	}
}
