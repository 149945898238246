const initState = {
	/* 
	  "tab title": {
		  data from server
	  }
	*/
}

export const shortReducer = (state = initState, action) => {
	switch (action.type) {
		case 'Set_Short_Data': {
			return {
				...state,
				...action.payload,
			}
		}

		default:
			return state
	}
}
