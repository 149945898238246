const initState = {
	header: {
		scroll_top: 0,
		direction: '' /* forward, backward */,
	},
}

export const scrollTopReducer = (state = initState, action) => {
	switch (action.type) {
		case 'Set_Scroll_Data': {
			return {
				...state,
				...action.payload,
			}
		}

		default:
			return state
	}
}
