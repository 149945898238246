const initState = {
	long_recommand: {
		show: false,
		filter_key: [],
	},
	short_recommand: {
		show: false,
		filter_key: [],
	},
}

export const popupReducer = (state = initState, action) => {
	switch (action.type) {
		case 'Set_Popup_Data_Long_Reco': {
			return {
				...state,
				long_recommand: {
					...state.long_recommand,
					...action.payload,
				},
			}
		}
		case 'Set_Popup_Data_Short_Reco': {
			return {
				...state,
				short_recommand: {
					...state.short_recommand,
					...action.payload,
				},
			}
		}

		default:
			return state
	}
}
