import { sb_ticket_order } from 'config/ticket-tab'

const initState = {
	/*
	  ss: show skeleton 
	  sc: show content
	*/

	'/l': {
		show: false,
	},
	'/s': {
		show: false,
	},
	'/e': {
		show: false,
	},
	'/c': {
		show: false,
	},
	'/m': {
		show: false,
	},
	'/404': {
		show: false,
	},
	long_content_tab: '' /* 從 server 回傳回來的 title */,
	short_content_tab: '' /* 從 server 回傳回來的 title */,
	collect_content_tab: '' /* l(long) | s(short) | a(actor) */,
	explore_content_tab: '' /* r(rank) | a(actress) | l(long) | s(short) */,

	sb_filterkeys_content_tab: 'Phim' /* Phim | Video */,
	sb_ticket_content_tab:
		sb_ticket_order /* {sb_ticket_order} | {sb_ticket_coupon} */,
	sb_vihi_content_tab: 'Phim' /* Phim | Video */,
}

export const softRouteReducer = (state = initState, action) => {
	switch (action.type) {
		case 'Set_Soft_Route': {
			return {
				...state,
				...action.payload,
			}
		}

		default:
			return state
	}
}
