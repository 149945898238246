const initState = {
	/*
     img_key: img_src  
   */
}

export const loadedimgReducer = (state = initState, action) => {
	switch (action.type) {
		case 'Set_Loaded_Img_Src': {
			return {
				...state,
				...action.payload,
			}
		}

		default:
			return state
	}
}
