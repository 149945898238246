import { createStore, combineReducers } from 'redux'
import { appReducer } from './app'
import { filterBoardReducer } from './filterboard'
import { initialReducer } from './initial'
import { loadedimgReducer } from './loadedimg'
import { longReducer } from './long'
import { popupReducer } from './popup'
import { scrollTopReducer } from './scrolltop'
import { shortReducer } from './short'
import { softRouteReducer } from './softroute'
import { trickyReducer } from './tricky'
// reducer
export const rootReducers = combineReducers({
	app: appReducer,
	softroute: softRouteReducer,
	filterboard: filterBoardReducer,
	scrolltop: scrollTopReducer,
	popup: popupReducer,
	tricky: trickyReducer,
	initial: initialReducer,
	long: longReducer,
	short: shortReducer,
	loadedimg: loadedimgReducer,
})

// store
const store = createStore(
	rootReducers,
	window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
)

export default store
