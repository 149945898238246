const initState = {
	long_content_slider_ref: null,
}

export const trickyReducer = (state = initState, action) => {
	switch (action.type) {
		case 'Set_Tricky_Data': {
			return {
				...state,
				...action.payload,
			}
		}

		default:
			return state
	}
}
