const initState = {
	is_window_focus: true,
	header_search_open: false,
}

export const appReducer = (state = initState, action) => {
	switch (action.type) {
		case 'Set_App_Info': {
			return {
				...state,
				...action.payload,
			}
		}

		default:
			return state
	}
}
